.react-datepicker-popper {
    // z-index: 1035;
    transform: translate(10px, 42px) !important;
}
.react-datepicker-wrapper {
    display: block !important;
  }
.react-datepicker {
    display: block;
    border: none;
    background-color: transparent;
    .react-datepicker-popper & {
        background-color: $white;
        border: 1px solid $border-color;
    }

    .react-datepicker__navigation {
        &.react-datepicker__navigation--previous {
            &::before {
                content: "<<";
            }
        }
        &.react-datepicker__navigation--next {
            &::before {
                content: ">>";
            }
        }
    }

    .react-datepicker__month-container {
        float: none;

        .react-datepicker__header {
                border-bottom: none;
                background-color: transparent;

            .react-datepicker__current-month {
                color: color(gray);
            }

            .react-datepicker__day-names {
                display: flex;
                justify-content: space-around;

                .react-datepicker__day-name {
                    padding: 0.5rem 0;
                color: color(gray);
                }
            }
        }

        .react-datepicker__month {
            .react-datepicker__week {
                display: flex;
                justify-content: space-around;

                .react-datepicker__day {
                    padding: 0.5rem 0;
                    color: color(gray);                    

                    &.react-datepicker__day--selected, &.react-datepicker__day--today {
                        background-color: transparent;
                        position: relative;
                        z-index: 1;

                        &::before {
                            content: "";
                            width: 38px;
                            height: 38px;
                            background: theme-color(success);
                            border-radius: 100%;
                            display: block;
                            position: absolute;
                            z-index: -1;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }

                    &.react-datepicker__day--today {
                        color: $white;
                        font-weight: normal;

                        &::before {
                            background: theme-color(info);
                        }
                    }

                    &.react-datepicker__day--outside-month {
                        color: $gray;
                    }
                }
            }

        }
    }
}

.daterange-picker {
    display: flex;
    @media (max-width: 576px) {
        display: block;
    }

    .react-datepicker-wrapper {
        flex-grow: 1;
        @media (max-width: 576px) {
            width: 100%
        }
    }

    .range-seperator {
        margin:auto 2rem;
        @media (max-width: 576px) {
            width: 40px;
            display: block;
            text-align: center;
            margin: 1rem auto;
        }
    }
}