/* Cards */

.card {
  border-radius: $card-border-radius;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-title {
    color: $card-title-custom-color;
    margin-bottom: 1.125rem;
    text-transform: capitalize;
  }
  
  .card-subtitle {
    @extend .text-gray;
    font-weight: normal;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .card-description {
    color: $card-description-color;
    font-weight: $font-weight-light;
  }
  &.card-outline-success {
    border: 1px solid theme-color("success");
  }
  &.card-outline-primary {
    border: 1px solid theme-color("primary");
  }
  &.card-outline-warning {
    border: 1px solid theme-color("warning");
  }
  &.card-outline-danger {
    border: 1px solid theme-color("danger");
  }
  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
  &.corona-gradient-card {
    background-image: linear-gradient(to left, #d41459, #911a6c);
    border-radius: 6px;
    .gradient-corona-img {
      height: 85px;
      @media (max-width: 576px) {
        height: auto;
        min-height: 50px;
        max-height: 85px;
      }
    }
    @media (max-width: 576px) {
      h4 {
        font-size: 0.75rem;
      }
    }
  }
  
}
.hl {
  position: relative;
  border-top: 1px solid #958d8d94;
  width: 90%;
  margin: 30px 0.75rem;
  align-items: center;
  text-align: center;
}

.hl-innertext {
  position: relative;
  color: black;
  font-weight: bold;
  transform: translate(320%, -50%);
  background-color: #e6e6e6;
  border: 1px solid #958d8d94;
  border-radius: 50%;
  padding: 10px;
  vertical-align: middle;

  top: -14px;
}

.social-container {
  margin: 20px 0;
  color: black;
}

.social-container a {
  border: 1px solid black;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(
      rgba(theme-color($color), 0.9),
      theme-color-level($color, 1),
      theme-color-level($color, 3)
    );
  }
}
