#progressbar {
	margin-bottom: 10px;
	overflow: hidden;
	counter-reset: step;
}
#progressbar li {
	list-style-type: none;
	color: #0090e7;
	text-transform: uppercase;
	font-size: 9px;
	width: 33.33%;
	float: left;
	position: relative;
}
#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 20px;
	line-height: 20px;
	display: block;
	font-size: 10px;
	color: #333;
	background: white;
	border-radius: 3px;
	margin: 0 auto 5px auto;
    padding-left: 6px;
}
#progressbar li:after {
	content: '';
	width: 100%;
	height: 2px;
	background: #0090e7;
	position: absolute;
	left: -50%;
	top: 9px;
	z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
	content: none; 
}
#progressbar li.active:before,  #progressbar li.active:after{
	background: #0090e7;
	color: white;
}