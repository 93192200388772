// /* Dropdowns */

.dropdown {
  .dropdown-toggle{
    &.no-caret {
      &::after {
        display: none;
      }
    }
  }
  .dropdown-menu {
    margin-top: .75rem;
    font-size: $default-font-size;
    box-shadow: $dropdown-menu-box-shadow;
    .dropdown-item {
      font-size: 1rem;
      padding: .25rem 1.5rem;
      &:focus{
        color: #ffffff;
      }
      &:active {
        background: initial;
      }
      &:hover {
        color: $dropdown-item-hover-color;
      }
    }
    &.sidebar-dropdown {
      &.dropdown-menu-right {
        .rtl & {
          left: 10rem !important;
        }
      }
    }
  }
}